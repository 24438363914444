// COPYRIGHT (C) [2023], CompScience, Inc.
// This software is proprietary and confidential. Unauthorized copying,
// distribution, modification, or use is strictly prohibited. This software is provided
// "as is," without warranty of any kind.

import { TextField, Select, Typography, MenuItem, Button, Radio, Tabs } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { DatePicker, StaticDateRangePicker } from '@material-ui/pickers';
import { TextValidator } from 'react-material-ui-form-validator';
import styled from 'styled-components';

export const CommonSelect = styled(Select)({
    borderRadius: '8px',
    minWidth: 130,
    '& .MuiOutlinedInput-input': {
        padding: '14px',
        borderRadius: '8px',
        fontFamily: 'Spline Sans',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '140%',
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #C6CBCC !important',
    },
    '& .MuiList-padding': {
        padding: ' 0 !important',
    },
    '& .MuiPopover-root > .MuiPaper-rounded': {
        height: '500px !important',
    },
});

export const CommonSelectUserSitting = styled(CommonSelect)({
    '& > fieldset': {
        border: '1px solid #C6CBCC',
        borderRadius: '8px',
        fontFamily: 'Spline Sans',
        fontSize: '14px',
        top: '-27px',
    },
});

export const CommonMenuItem = styled(MenuItem)({
    margin: ' 0 12px',
    borderRadius: '4px',
    color: '#949899',
    fontSize: '14px',
});

export const CommonDatePicker = styled(DatePicker)({
    fontSize: '14px',
    lineHeight: '115%',
    fontFamily: 'Spline Sans',
    '& > .MuiPickersDay-dayLabel': {
        fontSize: '14px',
        lineHeight: '115%',
        fontFamily: 'Spline Sans',
    },
    '& .MuiPickersDay-root': {
        fontSize: '14px',
        lineHeight: '115%',
        fontFamily: 'Spline Sans',
    },
    '& .MuiPickersCalendar-weekDayLabel': {
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '115%',
        color: ' #00C57B',
        fontFamily: 'Spline Sans',
    },
    '& .MuiPickersDay-root.Mui-selected': {
        background: '#00C57B',
    },
    '& .MuiInput-underline:after': {
        border: '1px solid #C6CBCC',
    },
});

export const CommonTextField = styled(TextField)({
    borderRadius: ' 8px',

    '& label.Mui-focused': {
        color: '1px solid #C6CBCC',
    },
    '& .MuiInput-underline:after': {
        border: '1px solid #C6CBCC',
    },
    '& .MuiOutlinedInput-root': {
        borderRadius: '8px',

        '& fieldset': {
            border: '1px solid #C6CBCC',
            borderRadius: '8px',
            fontFamily: 'Spline Sans',
            fontSize: '14px',
        },
        '&:hover fieldset': {
            border: '1px solid #C6CBCC',
        },
        '&.Mui-focused fieldset': {
            border: '1px solid #C6CBCC',
        },
    },
    '& .MuiOutlinedInput-input': {
        padding: '15.5px 14px',
        borderRadius: '8px',
        fontFamily: 'Spline Sans',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '140%',
    },
    '& .MuiOutlinedInput-multiline': {
        padding: 0,
    },
});
export const CommonTextFieldHaveIcon = styled(CommonTextField)({
    '& .MuiOutlinedInput-input': {
        paddingLeft: '0',
    },
});
export const CommonTypography = styled(Typography)(({ fontStyle = 'normal' }) => ({
    fontFamily: 'Spline Sans',
    fontStyle: fontStyle,
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '100%',
    color: ' #1A1A1A',
    marginBottom: '8px',
}));

export const InputContainer = styled.div({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
});

export const TitleHeader = styled.div({
    fontFamily: 'Spline Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 20,
    lineHeight: '100%',
    color: '#01232C',
    display: 'flex',
    alignItems: 'center',
    height: 70,
    backgroundColor: '#FFFFFF',
});

export const HeaderContainer = styled.div({
    fontFamily: 'Spline Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 20,
    lineHeight: '100%',
    color: '#01232C',
    display: 'flex',
    alignItems: 'center',
    height: 70,
    backgroundColor: '#FFFFFF',
    justifyContent: 'space-between',
    padding: '0 24px',
    '@media (max-width: 1240px)': {
        marginTop: 0,
    },
});

export const CommonButtonSubmit = styled(Button)(
    ({ hoverColor = '#007045 !important', disabledColor = '#FFFFFF', background = '#00C57B', width = '100%' }) => ({
        color: '#FFFFFF',
        borderRadius: '100px',
        width: width,
        fontFamily: 'Spline Sans',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '145%',
        textTransform: 'capitalize',
        height: 40,
        background: background,
        boxShadow: 'none',
        marginTop: 12,
        '&:hover': {
            background: hoverColor,
        },
        '&.MuiButton-outlined': {
            border: 'none',
        },
        '&:disabled': {
            color: disabledColor,
            cursor: 'not-allowed',
            pointerEvents: 'all !important',
            background: '#00C57B !important',
        },
    })
);

export const CancelButton = styled(Button)({
    color: '#FFFFFF',
    borderRadius: '100px',
    width: '100%',
    fontFamily: 'Spline Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '145%',
    textTransform: 'capitalize',
    height: 40,
    background: '#00C57B',
    boxShadow: 'none',
    marginTop: 12,
    '&.MuiButton-outlined': {
        border: 'none',
    },
});

export const CommonButtonSubmitDisabled = styled(CommonButtonSubmit)({
    '&:disabled:hover': {
        color: '#FFFFFF',
        cursor: 'not-allowed',
        background: '#007045',
        pointerEvents: 'all !important',
    },
});

export const CommonButton = styled(Button)`
    border: 1px solid #c6cbcc;
    background: transparent;
    font-family: Spline Sans !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16 !important;
    line-height: 145% !important;
    color: #4e4e4e;
    border-radius: 100px;
    height: 40px;
    &:hover {
        background-color: ${({ hoverBg = 'rgba(0, 197, 123, 0.1) !important' }) => hoverBg};
        border: 1px solid #009960;
        color: ${({ hoverColor = '#009960' }) => hoverColor};
        svg {
            path {
                stroke: #009960;
            }
        }
    }
    &.muibutton-outlined {
        border: none;
    }
    &.MuiButton-contained {
        box-shadow: none;
    }
`;

export const CommonTextValidator = styled(TextValidator)({
    borderRadius: ' 8px',

    '& label.Mui-focused': {
        color: '1px solid #C6CBCC',
    },
    '& .MuiInput-underline:after': {
        border: '1px solid #C6CBCC',
    },
    '& .MuiOutlinedInput-root': {
        borderRadius: '8px',
        '& fieldset': {
            border: '1px solid #C6CBCC',
            borderRadius: '8px',
            fontFamily: 'Spline Sans',
            fontSize: '14px',
            top: '-27px',
            '& .PrivateNotchedOutline-root-91': {
                top: '-27px',
            },
        },
        '&:hover fieldset': {
            border: '1px solid #C6CBCC',
        },
        '&.Mui-focused fieldset': {
            border: '1px solid #C6CBCC',
        },
    },
    '& .MuiOutlinedInput-input': {
        padding: '15.5px 14px',
        borderRadius: '8px',
        fontFamily: 'Spline Sans',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '140%',
    },
});

export const CommonStaticDateRangePicker = styled(StaticDateRangePicker)({
    fontSize: '14px',
    lineHeight: '115%',
    fontFamily: 'Spline Sans',
    '& > .MuiDivider-middle': {
        marginRight: 0,
    },
    '& > .MuiPickersDay-dayLabel': {
        fontSize: '14px',
        lineHeight: '115%',
        fontFamily: 'Spline Sans',
    },
    '& .MuiPickersDay-root': {
        fontSize: '14px',
        lineHeight: '115%',
        fontFamily: 'Spline Sans',
    },
    '& .MuiPickersDay-root.Mui-selected': {
        background: '#00C57B',
        fontSize: '14px',
        lineHeight: '115%',
        fontFamily: 'Spline Sans',
    },
    '& .MuiPickersDateRangeDay-rangeIntervalDayHighlight': {
        background: 'rgb(0, 197, 123,0.5)',
        fontSize: '14px',
        lineHeight: '115%',
        fontFamily: 'Spline Sans',
    },
    '& .MuiPickersCalendar-weekDayLabel': {
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '115%',
        color: '#00C57B',
        fontFamily: 'Spline Sans',
    },
    ' & .MuiPickersDesktopDateRangeCalendar-rangeCalendarContainer': {
        borderRight: '1px solid #E1E5E6 !important;',
    },
    '& .MuiGrid-grid-xs-10': {
        flexBasis: '0 !important;',
    },
    '& .Mui-disabled': {
        background: 'rgba(0, 0, 0, 0.04)',
    },
});

export const CommonAutocomplete = styled(Autocomplete)({
    borderRadius: '8px',
    minWidth: 130,
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
        padding: '6px',
        borderRadius: '8px',
        fontFamily: 'Spline Sans',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '140%',
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #C6CBCC !important',
    },
    '& .MuiList-padding': {
        padding: ' 0 !important',
    },
});

export const CommonRadio = styled(Radio)({
    label: {
        color: ' #C6CBCC',
        '&$checked': {
            color: ' #C6CBCC',
        },
    },
});

export const CommonTabsVertical = styled(Tabs)({
    '& .MuiTabs-flexContainerVertical': {
        alignItems: 'center',
    },
});
